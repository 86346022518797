import React, { lazy, Suspense } from 'react'
import { Provider } from 'react-redux'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { recaptchaSiteKey } from '@/shared/helpers/reCaptcha'
import GoogleReCaptchaVerifier from '@/public/GoogleReCaptchaVerifier'
import Portal from '@/shared/Portal'
import store from '@/public/redux/store'

export default function Loader({ component = null, data }) {
  if (component === null || 'name' in component === false) {
    if (console) {
      console.error('YOU MUST PASS IN COMPONENT INFO')
    }
    return ''
  }

  const Assessment = lazy(() => import('@/public/assessment'))
  const JoinButton = lazy(() => import('@/public/product/JoinButton'))
  const CartButton = lazy(() => import('@/public/cart/CartButton'))
  const CartDrawer = lazy(() => import('@/public/cart/CartDrawer'))
  const CartMembershipToggle = lazy(() => import('@/public/product/CartMembershipToggle'))
  const Checkout = lazy(() => import('@/public/checkout/Checkout'))
  const NewsletterForm = lazy(() => import('@/public/NewsletterForm'))
  const PriceBlockWithQuantity = lazy(() => import('@/public/product/PriceBlockWithQuantity'))
  const PriceBlockWithoutQuantity = lazy(() => import('@/public/product/PriceBlockWithoutQuantity'))
  const ProductCardButtons = lazy(() => import('@/public/product/ProductCardButtons'))
  const ProductUtilityActions = lazy(() => import('@/public/product/ProductUtilityActions'))
  const UpdatePaymentMethodForm = lazy(() => import('@/public/UpdatePaymentMethodForm'))
  const Recommendations = lazy(() => import('@/public/product/Recommendations'))
  const AutoAddToCart = lazy(() => import('@/public/product/AutoAddToCart'))
  const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY)

  const renderComponent = () => {
    switch (true) {
      case component.name === 'Assessment':
        return (
          <Portal
            Component={Assessment}
            container={document.getElementById(component?.root || '')}
            data={data}
            debug={component.debug}
          />
        )

      case component.name === 'JoinButton':
        return (
          <Portal
            Component={JoinButton}
            container={document.getElementById(component?.root || '')}
            product={data.product}
            text={data.text}
            className={data.className}
            disabled={data.disabled}
            subscription={data.subscription}
            debug={component?.debug}
            hidden={data.hidden}
          />
        )

      case component.name === 'CartButton':
        return (
          <Portal
            Component={CartButton}
            container={document.getElementById(component?.root || '')}
            userCartId={data.cart_id}
            mobile={component?.mobile}
            debug={component?.debug}
          />
        )

      case component.name === 'CartDrawer':
        return (
          <CartDrawer
            checkoutUrl={data.checkout_url}
            customerIsMember={data.customer_is_member}
            monthlyMembership={data.monthly_membership}
            annualMembership={data.annual_membership}
            debug={component?.debug}
          />
        )

      case component.name === 'Checkout':
        const cart = data.cart
        const amount = Math.round(cart.calculated_total_after_savings * 100)
        const appearance = {
          theme: 'stripe',
          variables: {
            fontFamily: 'Lato, sans-serif',
            fontSizeBase: '17.5px',
            fontLineHeight: '24px',
            fontWeightNormal: 400,
          },
          rules: {
            '.Input': {
              border: '2px solid #7b796b',
            },
            '.Input:focus': {
              border: '2px solid #4884e0',
              boxShadow: 'none',
            },
          },
        }

        let mode
        if (amount > 0) {
          mode = cart.only_contains_membership ? 'subscription' : 'payment'
        } else {
          mode = 'setup'
        }

        const options = {
          appearance,
        }

        if (data.ephemeral_key) {
          options.clientSecret = data.client_secret
          options.customerSessionClientSecret = data.customer_session_client_secret
          options.customerOptions = { customer: data.customer.stripe_customer_id, ephemeralKey: data.ephemeral_key }
        } else {
          options.mode = mode
          options.currency = 'usd'
          options.amount = amount
          if (cart.contains_subscriptions) {
            options.setupFutureUsage = 'off_session'
          }
        }
        console.error('options', options)
        return (
          <Elements stripe={stripePromise} options={options}>
            <Portal
              Component={Checkout}
              container={document.getElementById(component?.root || '')}
              knownCustomer={data.customer}
              cart={data.cart}
              states={data.states}
              provinces={data.provinces}
              countries={data.countries}
              store_default_path={data.store_default_path}
              confirmation_path={data.confirmation_path}
              terms_policy_modal_id={data.terms_policy_modal_id}
              cc_logos_url={data.cc_logos_url}
              shipping_costs={data.shipping_costs}
              debug={component?.debug}
              activeDiscountCodePresent={data.active_discount_code_present}
              accessMembershipTermsUrl={data.access_membership_terms_url}
              monthlyMembership={data.monthly_membership}
              annualMembership={data.annual_membership}
            />
          </Elements>
        )

      case component.name === 'CartMembershipToggle':
        return (
          <Portal
            Component={CartMembershipToggle}
            container={document.getElementById(component?.root || '')}
            product={data.product}
            debug={component?.debug}
          />
        )

      case component.name === 'UpdatePaymentMethodForm':
        return (
          <Elements stripe={stripePromise}>
            <Portal
              Component={UpdatePaymentMethodForm}
              container={document.getElementById(component?.root || '')}
              debug={component?.debug}
              user={data.user}
            />
          </Elements>
        )

      case component.name === 'NewsletterForm':
        return (
          <NewsletterForm
            downloadUrl={data?.download_url}
            downloadName={data?.download_name}
            buttonText={data?.button_text}
          />
        )

      case component.name === 'PriceBlockWithQuantity':
        return (
          <Portal
            Component={PriceBlockWithQuantity}
            container={document.getElementById(component?.root || '')}
            product={data.product}
            awaitedProductIds={data.awaited_product_ids}
            returnPolicy={data.return_policy}
            shippingPolicy={data.shipping_policy}
            debug={component?.debug}
            customerIsMember={data.customer_is_member}
            accessMembershipPrice={data.access_membership_price}
            annualAccessMembershipPrice={data.annual_access_membership_price}
          />
        )

      case component.name === 'PriceBlockWithoutQuantity':
        return (
          <Portal
            Component={PriceBlockWithoutQuantity}
            container={document.getElementById(component?.root || '')}
            product={data.product}
            returnPolicy={data.return_policy}
            shippingPolicy={data.shipping_policy}
            planTerms={data.plan_terms}
            applicationUrl={data.application_url}
            debug={component?.debug}
            customerIsMember={data.customer_is_member}
            accessMembershipPrice={data.access_membership_price}
            annualAccessMembershipPrice={data.annual_access_membership_price}
          />
        )

      case component.name === 'ProductCardButtons':
        return (
          <Portal
            Component={ProductCardButtons}
            container={document.getElementById(component?.root || '')}
            product={data.product}
            awaitedProductIds={data.awaited_product_ids}
            text={data?.text}
            textAlt={data?.text_alt}
            productUrl={data?.product_url}
          />
        )

      case component.name === 'ProductUtilityActions':
        return (
          <Portal
            Component={ProductUtilityActions}
            container={document.getElementById(component?.root || '')}
            product={data.product}
            customerIsMember={data.customer_is_member}
            debug={component?.debug}
          />
        )

      case component.name === 'Recommendations':
        return (
          <Portal
            Component={Recommendations}
            container={document.getElementById(component?.root || '')}
            recommendations={data}
            debug={component?.debug}
            title={component?.title}
            showMemberPricing={component?.show_member_pricing}
          />
        )

      case component.name === 'AutoAddToCart':
        return (
          <AutoAddToCart
            product={data.product}
            subscription={data.subscription}
          />
        )

      default:
        return ''
    }
  }

  return (
    process.env.RAILS_ENV === 'test' ? (
      <Provider store={store}>
        <Suspense fallback="">
          {renderComponent()}
        </Suspense>
      </Provider>
    ) : (
      <Provider store={store}>
        <GoogleReCaptchaProvider
          useEnterprise
          reCaptchaKey={recaptchaSiteKey}
        >
          <GoogleReCaptchaVerifier />
          <Suspense fallback="">
            {renderComponent()}
          </Suspense>
        </GoogleReCaptchaProvider>
      </Provider>
    )
  )
}
